var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
/*! For license information please see LICENSE */
!function (e, t) {
  exports = t();
}(self, () => (() => {
  "use strict";

  var e = {
      492: (e, t, n) => {
        n.r(t), n.d(t, {
          afterMain: () => E,
          afterRead: () => g,
          afterWrite: () => L,
          applyStyles: () => _,
          arrow: () => J,
          auto: () => a,
          basePlacements: () => l,
          beforeMain: () => b,
          beforeRead: () => y,
          beforeWrite: () => O,
          bottom: () => r,
          clippingParents: () => u,
          computeStyles: () => ne,
          createPopper: () => Ie,
          createPopperBase: () => Te,
          createPopperLite: () => _e,
          detectOverflow: () => ye,
          end: () => f,
          eventListeners: () => re,
          flip: () => we,
          hide: () => xe,
          left: () => s,
          main: () => x,
          modifierPhases: () => C,
          offset: () => Ee,
          placements: () => v,
          popper: () => d,
          popperGenerator: () => De,
          popperOffsets: () => Oe,
          preventOverflow: () => Se,
          read: () => w,
          reference: () => h,
          right: () => i,
          start: () => c,
          top: () => o,
          variationPlacements: () => m,
          viewport: () => p,
          write: () => S
        });
        var o = "top",
          r = "bottom",
          i = "right",
          s = "left",
          a = "auto",
          l = [o, r, i, s],
          c = "start",
          f = "end",
          u = "clippingParents",
          p = "viewport",
          d = "popper",
          h = "reference",
          m = l.reduce(function (e, t) {
            return e.concat([t + "-" + c, t + "-" + f]);
          }, []),
          v = [].concat(l, [a]).reduce(function (e, t) {
            return e.concat([t, t + "-" + c, t + "-" + f]);
          }, []),
          y = "beforeRead",
          w = "read",
          g = "afterRead",
          b = "beforeMain",
          x = "main",
          E = "afterMain",
          O = "beforeWrite",
          S = "write",
          L = "afterWrite",
          C = [y, w, g, b, x, E, O, S, L];
        function P(e) {
          return e ? (e.nodeName || "").toLowerCase() : null;
        }
        function A(e) {
          if (null == e) return window;
          if ("[object Window]" !== e.toString()) {
            var t = e.ownerDocument;
            return t && t.defaultView || window;
          }
          return e;
        }
        function D(e) {
          return e instanceof A(e).Element || e instanceof Element;
        }
        function T(e) {
          return e instanceof A(e).HTMLElement || e instanceof HTMLElement;
        }
        function I(e) {
          return "undefined" != typeof ShadowRoot && (e instanceof A(e).ShadowRoot || e instanceof ShadowRoot);
        }
        const _ = {
          name: "applyStyles",
          enabled: !0,
          phase: "write",
          fn: function (e) {
            var t = e.state;
            Object.keys(t.elements).forEach(function (e) {
              var n = t.styles[e] || {},
                o = t.attributes[e] || {},
                r = t.elements[e];
              T(r) && P(r) && (Object.assign(r.style, n), Object.keys(o).forEach(function (e) {
                var t = o[e];
                !1 === t ? r.removeAttribute(e) : r.setAttribute(e, !0 === t ? "" : t);
              }));
            });
          },
          effect: function (e) {
            var t = e.state,
              n = {
                popper: {
                  position: t.options.strategy,
                  left: "0",
                  top: "0",
                  margin: "0"
                },
                arrow: {
                  position: "absolute"
                },
                reference: {}
              };
            return Object.assign(t.elements.popper.style, n.popper), t.styles = n, t.elements.arrow && Object.assign(t.elements.arrow.style, n.arrow), function () {
              Object.keys(t.elements).forEach(function (e) {
                var o = t.elements[e],
                  r = t.attributes[e] || {},
                  i = Object.keys(t.styles.hasOwnProperty(e) ? t.styles[e] : n[e]).reduce(function (e, t) {
                    return e[t] = "", e;
                  }, {});
                T(o) && P(o) && (Object.assign(o.style, i), Object.keys(r).forEach(function (e) {
                  o.removeAttribute(e);
                }));
              });
            };
          },
          requires: ["computeStyles"]
        };
        function j(e) {
          return e.split("-")[0];
        }
        var M = Math.max,
          H = Math.min,
          k = Math.round;
        function B() {
          var e = navigator.userAgentData;
          return null != e && e.brands && Array.isArray(e.brands) ? e.brands.map(function (e) {
            return e.brand + "/" + e.version;
          }).join(" ") : navigator.userAgent;
        }
        function q() {
          return !/^((?!chrome|android).)*safari/i.test(B());
        }
        function W(e, t, n) {
          void 0 === t && (t = !1), void 0 === n && (n = !1);
          var o = e.getBoundingClientRect(),
            r = 1,
            i = 1;
          t && T(e) && (r = e.offsetWidth > 0 && k(o.width) / e.offsetWidth || 1, i = e.offsetHeight > 0 && k(o.height) / e.offsetHeight || 1);
          var s = (D(e) ? A(e) : window).visualViewport,
            a = !q() && n,
            l = (o.left + (a && s ? s.offsetLeft : 0)) / r,
            c = (o.top + (a && s ? s.offsetTop : 0)) / i,
            f = o.width / r,
            u = o.height / i;
          return {
            width: f,
            height: u,
            top: c,
            right: l + f,
            bottom: c + u,
            left: l,
            x: l,
            y: c
          };
        }
        function R(e) {
          var t = W(e),
            n = e.offsetWidth,
            o = e.offsetHeight;
          return Math.abs(t.width - n) <= 1 && (n = t.width), Math.abs(t.height - o) <= 1 && (o = t.height), {
            x: e.offsetLeft,
            y: e.offsetTop,
            width: n,
            height: o
          };
        }
        function V(e, t) {
          var n = t.getRootNode && t.getRootNode();
          if (e.contains(t)) return !0;
          if (n && I(n)) {
            var o = t;
            do {
              if (o && e.isSameNode(o)) return !0;
              o = o.parentNode || o.host;
            } while (o);
          }
          return !1;
        }
        function Y(e) {
          return A(e).getComputedStyle(e);
        }
        function $(e) {
          return ["table", "td", "th"].indexOf(P(e)) >= 0;
        }
        function N(e) {
          return ((D(e) ? e.ownerDocument : e.document) || window.document).documentElement;
        }
        function K(e) {
          return "html" === P(e) ? e : e.assignedSlot || e.parentNode || (I(e) ? e.host : null) || N(e);
        }
        function U(e) {
          return T(e) && "fixed" !== Y(e).position ? e.offsetParent : null;
        }
        function z(e) {
          for (var t = A(e), n = U(e); n && $(n) && "static" === Y(n).position;) n = U(n);
          return n && ("html" === P(n) || "body" === P(n) && "static" === Y(n).position) ? t : n || function (e) {
            var t = /firefox/i.test(B());
            if (/Trident/i.test(B()) && T(e) && "fixed" === Y(e).position) return null;
            var n = K(e);
            for (I(n) && (n = n.host); T(n) && ["html", "body"].indexOf(P(n)) < 0;) {
              var o = Y(n);
              if ("none" !== o.transform || "none" !== o.perspective || "paint" === o.contain || -1 !== ["transform", "perspective"].indexOf(o.willChange) || t && "filter" === o.willChange || t && o.filter && "none" !== o.filter) return n;
              n = n.parentNode;
            }
            return null;
          }(e) || t;
        }
        function F(e) {
          return ["top", "bottom"].indexOf(e) >= 0 ? "x" : "y";
        }
        function X(e, t, n) {
          return M(e, H(t, n));
        }
        function G(e) {
          return Object.assign({}, {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }, e);
        }
        function Z(e, t) {
          return t.reduce(function (t, n) {
            return t[n] = e, t;
          }, {});
        }
        const J = {
          name: "arrow",
          enabled: !0,
          phase: "main",
          fn: function (e) {
            var t,
              n = e.state,
              a = e.name,
              c = e.options,
              f = n.elements.arrow,
              u = n.modifiersData.popperOffsets,
              p = j(n.placement),
              d = F(p),
              h = [s, i].indexOf(p) >= 0 ? "height" : "width";
            if (f && u) {
              var m = function (e, t) {
                  return G("number" != typeof (e = "function" == typeof e ? e(Object.assign({}, t.rects, {
                    placement: t.placement
                  })) : e) ? e : Z(e, l));
                }(c.padding, n),
                v = R(f),
                y = "y" === d ? o : s,
                w = "y" === d ? r : i,
                g = n.rects.reference[h] + n.rects.reference[d] - u[d] - n.rects.popper[h],
                b = u[d] - n.rects.reference[d],
                x = z(f),
                E = x ? "y" === d ? x.clientHeight || 0 : x.clientWidth || 0 : 0,
                O = g / 2 - b / 2,
                S = m[y],
                L = E - v[h] - m[w],
                C = E / 2 - v[h] / 2 + O,
                P = X(S, C, L),
                A = d;
              n.modifiersData[a] = ((t = {})[A] = P, t.centerOffset = P - C, t);
            }
          },
          effect: function (e) {
            var t = e.state,
              n = e.options.element,
              o = void 0 === n ? "[data-popper-arrow]" : n;
            null != o && ("string" != typeof o || (o = t.elements.popper.querySelector(o))) && V(t.elements.popper, o) && (t.elements.arrow = o);
          },
          requires: ["popperOffsets"],
          requiresIfExists: ["preventOverflow"]
        };
        function Q(e) {
          return e.split("-")[1];
        }
        var ee = {
          top: "auto",
          right: "auto",
          bottom: "auto",
          left: "auto"
        };
        function te(e) {
          var t,
            n = e.popper,
            a = e.popperRect,
            l = e.placement,
            c = e.variation,
            u = e.offsets,
            p = e.position,
            d = e.gpuAcceleration,
            h = e.adaptive,
            m = e.roundOffsets,
            v = e.isFixed,
            y = u.x,
            w = void 0 === y ? 0 : y,
            g = u.y,
            b = void 0 === g ? 0 : g,
            x = "function" == typeof m ? m({
              x: w,
              y: b
            }) : {
              x: w,
              y: b
            };
          w = x.x, b = x.y;
          var E = u.hasOwnProperty("x"),
            O = u.hasOwnProperty("y"),
            S = s,
            L = o,
            C = window;
          if (h) {
            var P = z(n),
              D = "clientHeight",
              T = "clientWidth";
            P === A(n) && "static" !== Y(P = N(n)).position && "absolute" === p && (D = "scrollHeight", T = "scrollWidth"), (l === o || (l === s || l === i) && c === f) && (L = r, b -= (v && P === C && C.visualViewport ? C.visualViewport.height : P[D]) - a.height, b *= d ? 1 : -1), l !== s && (l !== o && l !== r || c !== f) || (S = i, w -= (v && P === C && C.visualViewport ? C.visualViewport.width : P[T]) - a.width, w *= d ? 1 : -1);
          }
          var I,
            _ = Object.assign({
              position: p
            }, h && ee),
            j = !0 === m ? function (e, t) {
              var n = e.x,
                o = e.y,
                r = t.devicePixelRatio || 1;
              return {
                x: k(n * r) / r || 0,
                y: k(o * r) / r || 0
              };
            }({
              x: w,
              y: b
            }, A(n)) : {
              x: w,
              y: b
            };
          return w = j.x, b = j.y, d ? Object.assign({}, _, ((I = {})[L] = O ? "0" : "", I[S] = E ? "0" : "", I.transform = (C.devicePixelRatio || 1) <= 1 ? "translate(" + w + "px, " + b + "px)" : "translate3d(" + w + "px, " + b + "px, 0)", I)) : Object.assign({}, _, ((t = {})[L] = O ? b + "px" : "", t[S] = E ? w + "px" : "", t.transform = "", t));
        }
        const ne = {
          name: "computeStyles",
          enabled: !0,
          phase: "beforeWrite",
          fn: function (e) {
            var t = e.state,
              n = e.options,
              o = n.gpuAcceleration,
              r = void 0 === o || o,
              i = n.adaptive,
              s = void 0 === i || i,
              a = n.roundOffsets,
              l = void 0 === a || a,
              c = {
                placement: j(t.placement),
                variation: Q(t.placement),
                popper: t.elements.popper,
                popperRect: t.rects.popper,
                gpuAcceleration: r,
                isFixed: "fixed" === t.options.strategy
              };
            null != t.modifiersData.popperOffsets && (t.styles.popper = Object.assign({}, t.styles.popper, te(Object.assign({}, c, {
              offsets: t.modifiersData.popperOffsets,
              position: t.options.strategy,
              adaptive: s,
              roundOffsets: l
            })))), null != t.modifiersData.arrow && (t.styles.arrow = Object.assign({}, t.styles.arrow, te(Object.assign({}, c, {
              offsets: t.modifiersData.arrow,
              position: "absolute",
              adaptive: !1,
              roundOffsets: l
            })))), t.attributes.popper = Object.assign({}, t.attributes.popper, {
              "data-popper-placement": t.placement
            });
          },
          data: {}
        };
        var oe = {
          passive: !0
        };
        const re = {
          name: "eventListeners",
          enabled: !0,
          phase: "write",
          fn: function () {},
          effect: function (e) {
            var t = e.state,
              n = e.instance,
              o = e.options,
              r = o.scroll,
              i = void 0 === r || r,
              s = o.resize,
              a = void 0 === s || s,
              l = A(t.elements.popper),
              c = [].concat(t.scrollParents.reference, t.scrollParents.popper);
            return i && c.forEach(function (e) {
              e.addEventListener("scroll", n.update, oe);
            }), a && l.addEventListener("resize", n.update, oe), function () {
              i && c.forEach(function (e) {
                e.removeEventListener("scroll", n.update, oe);
              }), a && l.removeEventListener("resize", n.update, oe);
            };
          },
          data: {}
        };
        var ie = {
          left: "right",
          right: "left",
          bottom: "top",
          top: "bottom"
        };
        function se(e) {
          return e.replace(/left|right|bottom|top/g, function (e) {
            return ie[e];
          });
        }
        var ae = {
          start: "end",
          end: "start"
        };
        function le(e) {
          return e.replace(/start|end/g, function (e) {
            return ae[e];
          });
        }
        function ce(e) {
          var t = A(e);
          return {
            scrollLeft: t.pageXOffset,
            scrollTop: t.pageYOffset
          };
        }
        function fe(e) {
          return W(N(e)).left + ce(e).scrollLeft;
        }
        function ue(e) {
          var t = Y(e),
            n = t.overflow,
            o = t.overflowX,
            r = t.overflowY;
          return /auto|scroll|overlay|hidden/.test(n + r + o);
        }
        function pe(e) {
          return ["html", "body", "#document"].indexOf(P(e)) >= 0 ? e.ownerDocument.body : T(e) && ue(e) ? e : pe(K(e));
        }
        function de(e, t) {
          var n;
          void 0 === t && (t = []);
          var o = pe(e),
            r = o === (null == (n = e.ownerDocument) ? void 0 : n.body),
            i = A(o),
            s = r ? [i].concat(i.visualViewport || [], ue(o) ? o : []) : o,
            a = t.concat(s);
          return r ? a : a.concat(de(K(s)));
        }
        function he(e) {
          return Object.assign({}, e, {
            left: e.x,
            top: e.y,
            right: e.x + e.width,
            bottom: e.y + e.height
          });
        }
        function me(e, t, n) {
          return t === p ? he(function (e, t) {
            var n = A(e),
              o = N(e),
              r = n.visualViewport,
              i = o.clientWidth,
              s = o.clientHeight,
              a = 0,
              l = 0;
            if (r) {
              i = r.width, s = r.height;
              var c = q();
              (c || !c && "fixed" === t) && (a = r.offsetLeft, l = r.offsetTop);
            }
            return {
              width: i,
              height: s,
              x: a + fe(e),
              y: l
            };
          }(e, n)) : D(t) ? function (e, t) {
            var n = W(e, !1, "fixed" === t);
            return n.top = n.top + e.clientTop, n.left = n.left + e.clientLeft, n.bottom = n.top + e.clientHeight, n.right = n.left + e.clientWidth, n.width = e.clientWidth, n.height = e.clientHeight, n.x = n.left, n.y = n.top, n;
          }(t, n) : he(function (e) {
            var t,
              n = N(e),
              o = ce(e),
              r = null == (t = e.ownerDocument) ? void 0 : t.body,
              i = M(n.scrollWidth, n.clientWidth, r ? r.scrollWidth : 0, r ? r.clientWidth : 0),
              s = M(n.scrollHeight, n.clientHeight, r ? r.scrollHeight : 0, r ? r.clientHeight : 0),
              a = -o.scrollLeft + fe(e),
              l = -o.scrollTop;
            return "rtl" === Y(r || n).direction && (a += M(n.clientWidth, r ? r.clientWidth : 0) - i), {
              width: i,
              height: s,
              x: a,
              y: l
            };
          }(N(e)));
        }
        function ve(e) {
          var t,
            n = e.reference,
            a = e.element,
            l = e.placement,
            u = l ? j(l) : null,
            p = l ? Q(l) : null,
            d = n.x + n.width / 2 - a.width / 2,
            h = n.y + n.height / 2 - a.height / 2;
          switch (u) {
            case o:
              t = {
                x: d,
                y: n.y - a.height
              };
              break;
            case r:
              t = {
                x: d,
                y: n.y + n.height
              };
              break;
            case i:
              t = {
                x: n.x + n.width,
                y: h
              };
              break;
            case s:
              t = {
                x: n.x - a.width,
                y: h
              };
              break;
            default:
              t = {
                x: n.x,
                y: n.y
              };
          }
          var m = u ? F(u) : null;
          if (null != m) {
            var v = "y" === m ? "height" : "width";
            switch (p) {
              case c:
                t[m] = t[m] - (n[v] / 2 - a[v] / 2);
                break;
              case f:
                t[m] = t[m] + (n[v] / 2 - a[v] / 2);
            }
          }
          return t;
        }
        function ye(e, t) {
          void 0 === t && (t = {});
          var n = t,
            s = n.placement,
            a = void 0 === s ? e.placement : s,
            c = n.strategy,
            f = void 0 === c ? e.strategy : c,
            m = n.boundary,
            v = void 0 === m ? u : m,
            y = n.rootBoundary,
            w = void 0 === y ? p : y,
            g = n.elementContext,
            b = void 0 === g ? d : g,
            x = n.altBoundary,
            E = void 0 !== x && x,
            O = n.padding,
            S = void 0 === O ? 0 : O,
            L = G("number" != typeof S ? S : Z(S, l)),
            C = b === d ? h : d,
            A = e.rects.popper,
            I = e.elements[E ? C : b],
            _ = function (e, t, n, o) {
              var r = "clippingParents" === t ? function (e) {
                  var t = de(K(e)),
                    n = ["absolute", "fixed"].indexOf(Y(e).position) >= 0 && T(e) ? z(e) : e;
                  return D(n) ? t.filter(function (e) {
                    return D(e) && V(e, n) && "body" !== P(e);
                  }) : [];
                }(e) : [].concat(t),
                i = [].concat(r, [n]),
                s = i[0],
                a = i.reduce(function (t, n) {
                  var r = me(e, n, o);
                  return t.top = M(r.top, t.top), t.right = H(r.right, t.right), t.bottom = H(r.bottom, t.bottom), t.left = M(r.left, t.left), t;
                }, me(e, s, o));
              return a.width = a.right - a.left, a.height = a.bottom - a.top, a.x = a.left, a.y = a.top, a;
            }(D(I) ? I : I.contextElement || N(e.elements.popper), v, w, f),
            j = W(e.elements.reference),
            k = ve({
              reference: j,
              element: A,
              strategy: "absolute",
              placement: a
            }),
            B = he(Object.assign({}, A, k)),
            q = b === d ? B : j,
            R = {
              top: _.top - q.top + L.top,
              bottom: q.bottom - _.bottom + L.bottom,
              left: _.left - q.left + L.left,
              right: q.right - _.right + L.right
            },
            $ = e.modifiersData.offset;
          if (b === d && $) {
            var U = $[a];
            Object.keys(R).forEach(function (e) {
              var t = [i, r].indexOf(e) >= 0 ? 1 : -1,
                n = [o, r].indexOf(e) >= 0 ? "y" : "x";
              R[e] += U[n] * t;
            });
          }
          return R;
        }
        const we = {
          name: "flip",
          enabled: !0,
          phase: "main",
          fn: function (e) {
            var t = e.state,
              n = e.options,
              f = e.name;
            if (!t.modifiersData[f]._skip) {
              for (var u = n.mainAxis, p = void 0 === u || u, d = n.altAxis, h = void 0 === d || d, y = n.fallbackPlacements, w = n.padding, g = n.boundary, b = n.rootBoundary, x = n.altBoundary, E = n.flipVariations, O = void 0 === E || E, S = n.allowedAutoPlacements, L = t.options.placement, C = j(L), P = y || (C !== L && O ? function (e) {
                  if (j(e) === a) return [];
                  var t = se(e);
                  return [le(e), t, le(t)];
                }(L) : [se(L)]), A = [L].concat(P).reduce(function (e, n) {
                  return e.concat(j(n) === a ? function (e, t) {
                    void 0 === t && (t = {});
                    var n = t,
                      o = n.placement,
                      r = n.boundary,
                      i = n.rootBoundary,
                      s = n.padding,
                      a = n.flipVariations,
                      c = n.allowedAutoPlacements,
                      f = void 0 === c ? v : c,
                      u = Q(o),
                      p = u ? a ? m : m.filter(function (e) {
                        return Q(e) === u;
                      }) : l,
                      d = p.filter(function (e) {
                        return f.indexOf(e) >= 0;
                      });
                    0 === d.length && (d = p);
                    var h = d.reduce(function (t, n) {
                      return t[n] = ye(e, {
                        placement: n,
                        boundary: r,
                        rootBoundary: i,
                        padding: s
                      })[j(n)], t;
                    }, {});
                    return Object.keys(h).sort(function (e, t) {
                      return h[e] - h[t];
                    });
                  }(t, {
                    placement: n,
                    boundary: g,
                    rootBoundary: b,
                    padding: w,
                    flipVariations: O,
                    allowedAutoPlacements: S
                  }) : n);
                }, []), D = t.rects.reference, T = t.rects.popper, I = new Map(), _ = !0, M = A[0], H = 0; H < A.length; H++) {
                var k = A[H],
                  B = j(k),
                  q = Q(k) === c,
                  W = [o, r].indexOf(B) >= 0,
                  R = W ? "width" : "height",
                  V = ye(t, {
                    placement: k,
                    boundary: g,
                    rootBoundary: b,
                    altBoundary: x,
                    padding: w
                  }),
                  Y = W ? q ? i : s : q ? r : o;
                D[R] > T[R] && (Y = se(Y));
                var $ = se(Y),
                  N = [];
                if (p && N.push(V[B] <= 0), h && N.push(V[Y] <= 0, V[$] <= 0), N.every(function (e) {
                  return e;
                })) {
                  M = k, _ = !1;
                  break;
                }
                I.set(k, N);
              }
              if (_) for (var K = function (e) {
                  var t = A.find(function (t) {
                    var n = I.get(t);
                    if (n) return n.slice(0, e).every(function (e) {
                      return e;
                    });
                  });
                  if (t) return M = t, "break";
                }, U = O ? 3 : 1; U > 0 && "break" !== K(U); U--);
              t.placement !== M && (t.modifiersData[f]._skip = !0, t.placement = M, t.reset = !0);
            }
          },
          requiresIfExists: ["offset"],
          data: {
            _skip: !1
          }
        };
        function ge(e, t, n) {
          return void 0 === n && (n = {
            x: 0,
            y: 0
          }), {
            top: e.top - t.height - n.y,
            right: e.right - t.width + n.x,
            bottom: e.bottom - t.height + n.y,
            left: e.left - t.width - n.x
          };
        }
        function be(e) {
          return [o, i, r, s].some(function (t) {
            return e[t] >= 0;
          });
        }
        const xe = {
            name: "hide",
            enabled: !0,
            phase: "main",
            requiresIfExists: ["preventOverflow"],
            fn: function (e) {
              var t = e.state,
                n = e.name,
                o = t.rects.reference,
                r = t.rects.popper,
                i = t.modifiersData.preventOverflow,
                s = ye(t, {
                  elementContext: "reference"
                }),
                a = ye(t, {
                  altBoundary: !0
                }),
                l = ge(s, o),
                c = ge(a, r, i),
                f = be(l),
                u = be(c);
              t.modifiersData[n] = {
                referenceClippingOffsets: l,
                popperEscapeOffsets: c,
                isReferenceHidden: f,
                hasPopperEscaped: u
              }, t.attributes.popper = Object.assign({}, t.attributes.popper, {
                "data-popper-reference-hidden": f,
                "data-popper-escaped": u
              });
            }
          },
          Ee = {
            name: "offset",
            enabled: !0,
            phase: "main",
            requires: ["popperOffsets"],
            fn: function (e) {
              var t = e.state,
                n = e.options,
                r = e.name,
                a = n.offset,
                l = void 0 === a ? [0, 0] : a,
                c = v.reduce(function (e, n) {
                  return e[n] = function (e, t, n) {
                    var r = j(e),
                      a = [s, o].indexOf(r) >= 0 ? -1 : 1,
                      l = "function" == typeof n ? n(Object.assign({}, t, {
                        placement: e
                      })) : n,
                      c = l[0],
                      f = l[1];
                    return c = c || 0, f = (f || 0) * a, [s, i].indexOf(r) >= 0 ? {
                      x: f,
                      y: c
                    } : {
                      x: c,
                      y: f
                    };
                  }(n, t.rects, l), e;
                }, {}),
                f = c[t.placement],
                u = f.x,
                p = f.y;
              null != t.modifiersData.popperOffsets && (t.modifiersData.popperOffsets.x += u, t.modifiersData.popperOffsets.y += p), t.modifiersData[r] = c;
            }
          },
          Oe = {
            name: "popperOffsets",
            enabled: !0,
            phase: "read",
            fn: function (e) {
              var t = e.state,
                n = e.name;
              t.modifiersData[n] = ve({
                reference: t.rects.reference,
                element: t.rects.popper,
                strategy: "absolute",
                placement: t.placement
              });
            },
            data: {}
          },
          Se = {
            name: "preventOverflow",
            enabled: !0,
            phase: "main",
            fn: function (e) {
              var t = e.state,
                n = e.options,
                a = e.name,
                l = n.mainAxis,
                f = void 0 === l || l,
                u = n.altAxis,
                p = void 0 !== u && u,
                d = n.boundary,
                h = n.rootBoundary,
                m = n.altBoundary,
                v = n.padding,
                y = n.tether,
                w = void 0 === y || y,
                g = n.tetherOffset,
                b = void 0 === g ? 0 : g,
                x = ye(t, {
                  boundary: d,
                  rootBoundary: h,
                  padding: v,
                  altBoundary: m
                }),
                E = j(t.placement),
                O = Q(t.placement),
                S = !O,
                L = F(E),
                C = "x" === L ? "y" : "x",
                P = t.modifiersData.popperOffsets,
                A = t.rects.reference,
                D = t.rects.popper,
                T = "function" == typeof b ? b(Object.assign({}, t.rects, {
                  placement: t.placement
                })) : b,
                I = "number" == typeof T ? {
                  mainAxis: T,
                  altAxis: T
                } : Object.assign({
                  mainAxis: 0,
                  altAxis: 0
                }, T),
                _ = t.modifiersData.offset ? t.modifiersData.offset[t.placement] : null,
                k = {
                  x: 0,
                  y: 0
                };
              if (P) {
                if (f) {
                  var B,
                    q = "y" === L ? o : s,
                    W = "y" === L ? r : i,
                    V = "y" === L ? "height" : "width",
                    Y = P[L],
                    $ = Y + x[q],
                    N = Y - x[W],
                    K = w ? -D[V] / 2 : 0,
                    U = O === c ? A[V] : D[V],
                    G = O === c ? -D[V] : -A[V],
                    Z = t.elements.arrow,
                    J = w && Z ? R(Z) : {
                      width: 0,
                      height: 0
                    },
                    ee = t.modifiersData["arrow#persistent"] ? t.modifiersData["arrow#persistent"].padding : {
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0
                    },
                    te = ee[q],
                    ne = ee[W],
                    oe = X(0, A[V], J[V]),
                    re = S ? A[V] / 2 - K - oe - te - I.mainAxis : U - oe - te - I.mainAxis,
                    ie = S ? -A[V] / 2 + K + oe + ne + I.mainAxis : G + oe + ne + I.mainAxis,
                    se = t.elements.arrow && z(t.elements.arrow),
                    ae = se ? "y" === L ? se.clientTop || 0 : se.clientLeft || 0 : 0,
                    le = null != (B = null == _ ? void 0 : _[L]) ? B : 0,
                    ce = Y + ie - le,
                    fe = X(w ? H($, Y + re - le - ae) : $, Y, w ? M(N, ce) : N);
                  P[L] = fe, k[L] = fe - Y;
                }
                if (p) {
                  var ue,
                    pe = "x" === L ? o : s,
                    de = "x" === L ? r : i,
                    he = P[C],
                    me = "y" === C ? "height" : "width",
                    ve = he + x[pe],
                    we = he - x[de],
                    ge = -1 !== [o, s].indexOf(E),
                    be = null != (ue = null == _ ? void 0 : _[C]) ? ue : 0,
                    xe = ge ? ve : he - A[me] - D[me] - be + I.altAxis,
                    Ee = ge ? he + A[me] + D[me] - be - I.altAxis : we,
                    Oe = w && ge ? function (e, t, n) {
                      var o = X(e, t, n);
                      return o > n ? n : o;
                    }(xe, he, Ee) : X(w ? xe : ve, he, w ? Ee : we);
                  P[C] = Oe, k[C] = Oe - he;
                }
                t.modifiersData[a] = k;
              }
            },
            requiresIfExists: ["offset"]
          };
        function Le(e, t, n) {
          void 0 === n && (n = !1);
          var o,
            r,
            i = T(t),
            s = T(t) && function (e) {
              var t = e.getBoundingClientRect(),
                n = k(t.width) / e.offsetWidth || 1,
                o = k(t.height) / e.offsetHeight || 1;
              return 1 !== n || 1 !== o;
            }(t),
            a = N(t),
            l = W(e, s, n),
            c = {
              scrollLeft: 0,
              scrollTop: 0
            },
            f = {
              x: 0,
              y: 0
            };
          return (i || !i && !n) && (("body" !== P(t) || ue(a)) && (c = (o = t) !== A(o) && T(o) ? {
            scrollLeft: (r = o).scrollLeft,
            scrollTop: r.scrollTop
          } : ce(o)), T(t) ? ((f = W(t, !0)).x += t.clientLeft, f.y += t.clientTop) : a && (f.x = fe(a))), {
            x: l.left + c.scrollLeft - f.x,
            y: l.top + c.scrollTop - f.y,
            width: l.width,
            height: l.height
          };
        }
        function Ce(e) {
          var t = new Map(),
            n = new Set(),
            o = [];
          function r(e) {
            n.add(e.name), [].concat(e.requires || [], e.requiresIfExists || []).forEach(function (e) {
              if (!n.has(e)) {
                var o = t.get(e);
                o && r(o);
              }
            }), o.push(e);
          }
          return e.forEach(function (e) {
            t.set(e.name, e);
          }), e.forEach(function (e) {
            n.has(e.name) || r(e);
          }), o;
        }
        var Pe = {
          placement: "bottom",
          modifiers: [],
          strategy: "absolute"
        };
        function Ae() {
          for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++) t[n] = arguments[n];
          return !t.some(function (e) {
            return !(e && "function" == typeof e.getBoundingClientRect);
          });
        }
        function De(e) {
          void 0 === e && (e = {});
          var t = e,
            n = t.defaultModifiers,
            o = void 0 === n ? [] : n,
            r = t.defaultOptions,
            i = void 0 === r ? Pe : r;
          return function (e, t, n) {
            void 0 === n && (n = i);
            var r,
              s,
              a = {
                placement: "bottom",
                orderedModifiers: [],
                options: Object.assign({}, Pe, i),
                modifiersData: {},
                elements: {
                  reference: e,
                  popper: t
                },
                attributes: {},
                styles: {}
              },
              l = [],
              c = !1,
              f = {
                state: a,
                setOptions: function (n) {
                  var r = "function" == typeof n ? n(a.options) : n;
                  u(), a.options = Object.assign({}, i, a.options, r), a.scrollParents = {
                    reference: D(e) ? de(e) : e.contextElement ? de(e.contextElement) : [],
                    popper: de(t)
                  };
                  var s,
                    c,
                    p = function (e) {
                      var t = Ce(e);
                      return C.reduce(function (e, n) {
                        return e.concat(t.filter(function (e) {
                          return e.phase === n;
                        }));
                      }, []);
                    }((s = [].concat(o, a.options.modifiers), c = s.reduce(function (e, t) {
                      var n = e[t.name];
                      return e[t.name] = n ? Object.assign({}, n, t, {
                        options: Object.assign({}, n.options, t.options),
                        data: Object.assign({}, n.data, t.data)
                      }) : t, e;
                    }, {}), Object.keys(c).map(function (e) {
                      return c[e];
                    })));
                  return a.orderedModifiers = p.filter(function (e) {
                    return e.enabled;
                  }), a.orderedModifiers.forEach(function (e) {
                    var t = e.name,
                      n = e.options,
                      o = void 0 === n ? {} : n,
                      r = e.effect;
                    if ("function" == typeof r) {
                      var i = r({
                        state: a,
                        name: t,
                        instance: f,
                        options: o
                      });
                      l.push(i || function () {});
                    }
                  }), f.update();
                },
                forceUpdate: function () {
                  if (!c) {
                    var e = a.elements,
                      t = e.reference,
                      n = e.popper;
                    if (Ae(t, n)) {
                      a.rects = {
                        reference: Le(t, z(n), "fixed" === a.options.strategy),
                        popper: R(n)
                      }, a.reset = !1, a.placement = a.options.placement, a.orderedModifiers.forEach(function (e) {
                        return a.modifiersData[e.name] = Object.assign({}, e.data);
                      });
                      for (var o = 0; o < a.orderedModifiers.length; o++) if (!0 !== a.reset) {
                        var r = a.orderedModifiers[o],
                          i = r.fn,
                          s = r.options,
                          l = void 0 === s ? {} : s,
                          u = r.name;
                        "function" == typeof i && (a = i({
                          state: a,
                          options: l,
                          name: u,
                          instance: f
                        }) || a);
                      } else a.reset = !1, o = -1;
                    }
                  }
                },
                update: (r = function () {
                  return new Promise(function (e) {
                    f.forceUpdate(), e(a);
                  });
                }, function () {
                  return s || (s = new Promise(function (e) {
                    Promise.resolve().then(function () {
                      s = void 0, e(r());
                    });
                  })), s;
                }),
                destroy: function () {
                  u(), c = !0;
                }
              };
            if (!Ae(e, t)) return f;
            function u() {
              l.forEach(function (e) {
                return e();
              }), l = [];
            }
            return f.setOptions(n).then(function (e) {
              !c && n.onFirstUpdate && n.onFirstUpdate(e);
            }), f;
          };
        }
        var Te = De(),
          Ie = De({
            defaultModifiers: [re, Oe, ne, _, Ee, we, Se, J, xe]
          }),
          _e = De({
            defaultModifiers: [re, Oe, ne, _]
          });
      },
      190: (e, t) => {
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), t.SELECT_ACCESSIBILITY_KEY_SET = t.TABS_ACCESSIBILITY_KEY_SET = t.OVERLAY_ACCESSIBILITY_KEY_SET = t.DROPDOWN_ACCESSIBILITY_KEY_SET = t.POSITIONS = void 0, t.POSITIONS = {
          auto: "auto",
          "auto-start": "auto-start",
          "auto-end": "auto-end",
          top: "top",
          "top-left": "top-start",
          "top-right": "top-end",
          bottom: "bottom",
          "bottom-left": "bottom-start",
          "bottom-right": "bottom-end",
          right: "right",
          "right-start": "right-start",
          "right-end": "right-end",
          left: "left",
          "left-start": "left-start",
          "left-end": "left-end"
        }, t.DROPDOWN_ACCESSIBILITY_KEY_SET = ["Escape", "ArrowUp", "ArrowDown", "Home", "End", "Enter"], t.OVERLAY_ACCESSIBILITY_KEY_SET = ["Escape", "Tab"], t.TABS_ACCESSIBILITY_KEY_SET = ["ArrowUp", "ArrowLeft", "ArrowDown", "ArrowRight", "Home", "End"], t.SELECT_ACCESSIBILITY_KEY_SET = ["ArrowUp", "ArrowLeft", "ArrowDown", "ArrowRight", "Home", "End", "Escape", "Enter", "Tab"];
      },
      737: (e, t) => {
        Object.defineProperty(t, "__esModule", {
          value: !0
        });
        var n = function () {
          function e(e, t, n) {
            (this || _global).el = e, (this || _global).options = t, (this || _global).events = n, (this || _global).el = e, (this || _global).options = t, (this || _global).events = {};
          }
          return e.prototype.isIOS = function () {
            return !!/iPad|iPhone|iPod/.test(navigator.platform) || navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
          }, e.prototype.isIpadOS = function () {
            return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
          }, e.prototype.createCollection = function (e, t) {
            var n;
            e.push({
              id: (null === (n = null == t ? void 0 : t.el) || void 0 === n ? void 0 : n.id) || e.length + 1,
              element: t
            });
          }, e.prototype.fireEvent = function (e, t) {
            if (void 0 === t && (t = null), (this || _global).events.hasOwnProperty(e)) return (this || _global).events[e](t);
          }, e.prototype.dispatch = function (e, t, n) {
            void 0 === n && (n = null);
            var o = new CustomEvent(e, {
              detail: {
                payload: n
              },
              bubbles: !0,
              cancelable: !0,
              composed: !1
            });
            t.dispatchEvent(o);
          }, e.prototype.on = function (e, t) {
            (this || _global).events[e] = t;
          }, e.prototype.afterTransition = function (e, t) {
            var n = function () {
              t(), e.removeEventListener("transitionend", n, !0);
            };
            "all 0s ease 0s" !== window.getComputedStyle(e, null).getPropertyValue("transition") ? e.addEventListener("transitionend", n, !0) : t();
          }, e.prototype.onTransitionEnd = function (e, t) {
            e.addEventListener("transitionend", function n(o) {
              o.target === e && (e.removeEventListener("transitionend", n), t());
            });
          }, e.prototype.getClassProperty = function (e, t, n) {
            return void 0 === n && (n = ""), (window.getComputedStyle(e).getPropertyValue(t) || n).replace(" ", "");
          }, e.prototype.getClassPropertyAlt = function (e, t, n) {
            void 0 === n && (n = "");
            var o = "";
            return e.classList.forEach(function (e) {
              e.includes(t) && (o = e);
            }), o.match(/:(.*)]/) ? o.match(/:(.*)]/)[1] : n;
          }, e.prototype.htmlToElement = function (e) {
            var t = document.createElement("template");
            return e = e.trim(), t.innerHTML = e, t.content.firstChild;
          }, e.prototype.classToClassList = function (e, t, n) {
            void 0 === n && (n = " "), e.split(n).forEach(function (e) {
              return t.classList.add(e);
            });
          }, e.prototype.debounce = function (e, t) {
            var n,
              o = this || _global;
            return void 0 === t && (t = 200), function () {
              for (var r = [], i = 0; i < arguments.length; i++) r[i] = arguments[i];
              clearTimeout(n), n = setTimeout(function () {
                e.apply(o, r);
              }, t);
            };
          }, e.prototype.checkIfFormElement = function (e) {
            return e instanceof HTMLInputElement || e instanceof HTMLTextAreaElement || e instanceof HTMLSelectElement;
          }, e.isEnoughSpace = function (e, t, n, o, r) {
            void 0 === n && (n = "auto"), void 0 === o && (o = 10), void 0 === r && (r = null);
            var i = t.getBoundingClientRect(),
              s = r ? r.getBoundingClientRect() : null,
              a = window.innerHeight,
              l = s ? i.top - s.top : i.top,
              c = (r ? s.bottom : a) - i.bottom,
              f = e.clientHeight + o;
            return "bottom" === n ? c >= f : "top" === n ? l >= f : l >= f || c >= f;
          }, e.isParentOrElementHidden = function (e) {
            return !!e && ("none" === window.getComputedStyle(e).display || this.isParentOrElementHidden(e.parentElement));
          }, e;
        }();
        t.default = n, window.HSStaticMethods = {
          afterTransition: function (e, t) {
            var n = function () {
              t(), e.removeEventListener("transitionend", n, !0);
            };
            "all 0s ease 0s" !== window.getComputedStyle(e, null).getPropertyValue("transition") ? e.addEventListener("transitionend", n, !0) : t();
          },
          getClassPropertyAlt: function (e, t, n) {
            void 0 === n && (n = "");
            var o = "";
            return e.classList.forEach(function (e) {
              e.includes(t) && (o = e);
            }), o.match(/:(.*)]/) ? o.match(/:(.*)]/)[1] : n;
          },
          getClassProperty: function (e, t, n) {
            return void 0 === n && (n = ""), (window.getComputedStyle(e).getPropertyValue(t) || n).replace(" ", "");
          }
        };
      },
      610: function (e, t, n) {
        var o,
          r = (this || _global) && (this || _global).__extends || (o = function (e, t) {
            return o = Object.setPrototypeOf || {
              __proto__: []
            } instanceof Array && function (e, t) {
              e.__proto__ = t;
            } || function (e, t) {
              for (var n in t) Object.prototype.hasOwnProperty.call(t, n) && (e[n] = t[n]);
            }, o(e, t);
          }, function (e, t) {
            if ("function" != typeof t && null !== t) throw new TypeError("Class extends value " + String(t) + " is not a constructor or null");
            function n() {
              (this || _global).constructor = e;
            }
            o(e, t), e.prototype = null === t ? Object.create(t) : (n.prototype = t.prototype, new n());
          }),
          i = (this || _global) && (this || _global).__spreadArray || function (e, t, n) {
            if (n || 2 === arguments.length) for (var o, r = 0, i = t.length; r < i; r++) !o && r in t || (o || (o = Array.prototype.slice.call(t, 0, r)), o[r] = t[r]);
            return e.concat(o || Array.prototype.slice.call(t));
          };
        Object.defineProperty(t, "__esModule", {
          value: !0
        });
        var s = n(737),
          a = n(190),
          l = n(492),
          c = n(969),
          f = function (e) {
            function t(t, n, o) {
              var r = e.call(this || _global, t, n, o) || this || _global;
              return r.toggle = r.el.querySelector(":scope > .hs-dropdown-toggle") || r.el.children[0], r.menu = r.el.querySelector(":scope > .hs-dropdown-menu"), r.eventMode = r.getClassProperty(r.el, "--trigger", "click"), r.closeMode = r.getClassProperty(r.el, "--auto-close", "true"), r.animationInProcess = !1, r.toggle && r.menu && r.init(), r;
            }
            return r(t, e), t.prototype.init = function () {
              var e = this || _global;
              if (this.createCollection(window.$hsDropdownCollection, this || _global), (this || _global).toggle.disabled) return !1;
              (this || _global).toggle.addEventListener("click", function () {
                return e.onClickHandler();
              }), this.isIOS() || this.isIpadOS() || ((this || _global).el.addEventListener("mouseenter", function () {
                return e.onMouseEnterHandler();
              }), (this || _global).el.addEventListener("mouseleave", function () {
                return e.onMouseLeaveHandler();
              }));
            }, t.prototype.resizeHandler = function () {
              (this || _global).eventMode = this.getClassProperty((this || _global).el, "--trigger", "click");
            }, t.prototype.onClickHandler = function () {
              (this || _global).el.classList.contains("open") && !(this || _global).menu.classList.contains("hidden") ? this.close() : this.open();
            }, t.prototype.onMouseEnterHandler = function () {
              if ("hover" !== (this || _global).eventMode) return !1;
              (this || _global).el._popper && this.forceClearState(), !(this || _global).el.classList.contains("open") && (this || _global).menu.classList.contains("hidden") && this.open();
            }, t.prototype.onMouseLeaveHandler = function () {
              if ("hover" !== (this || _global).eventMode) return !1;
              (this || _global).el.classList.contains("open") && !(this || _global).menu.classList.contains("hidden") && this.close();
            }, t.prototype.destroyPopper = function () {
              (this || _global).menu.classList.remove("block"), (this || _global).menu.classList.add("hidden"), (this || _global).menu.style.inset = null, (this || _global).menu.style.position = null, (this || _global).el && (this || _global).el._popper && (this || _global).el._popper.destroy(), (this || _global).animationInProcess = !1;
            }, t.prototype.absoluteStrategyModifiers = function () {
              var e = this || _global;
              return [{
                name: "applyStyles",
                fn: function (t) {
                  var n = (window.getComputedStyle(e.el).getPropertyValue("--strategy") || "absolute").replace(" ", ""),
                    o = (window.getComputedStyle(e.el).getPropertyValue("--adaptive") || "adaptive").replace(" ", "");
                  t.state.elements.popper.style.position = n, t.state.elements.popper.style.transform = "adaptive" === o ? t.state.styles.popper.transform : null, t.state.elements.popper.style.top = null, t.state.elements.popper.style.bottom = null, t.state.elements.popper.style.left = null, t.state.elements.popper.style.right = null, t.state.elements.popper.style.margin = 0;
                }
              }, {
                name: "computeStyles",
                options: {
                  adaptive: !1
                }
              }];
            }, t.prototype.open = function () {
              var e = this || _global;
              if ((this || _global).el.classList.contains("open")) return !1;
              if ((this || _global).animationInProcess) return !1;
              (this || _global).animationInProcess = !0;
              var t = (window.getComputedStyle((this || _global).el).getPropertyValue("--placement") || "").replace(" ", ""),
                n = (window.getComputedStyle((this || _global).el).getPropertyValue("--flip") || "true").replace(" ", ""),
                o = (window.getComputedStyle((this || _global).el).getPropertyValue("--strategy") || "fixed").replace(" ", ""),
                r = parseInt((window.getComputedStyle((this || _global).el).getPropertyValue("--offset") || "10").replace(" ", ""));
              "static" !== o && ((this || _global).el._popper = (0, l.createPopper)((this || _global).el, (this || _global).menu, {
                placement: a.POSITIONS[t] || "bottom-start",
                strategy: o,
                modifiers: i(i([], "fixed" !== o ? this.absoluteStrategyModifiers() : [], !0), [{
                  name: "flip",
                  enabled: "true" === n
                }, {
                  name: "offset",
                  options: {
                    offset: [0, r]
                  }
                }], !1)
              })), (this || _global).menu.style.margin = null, (this || _global).menu.classList.remove("hidden"), (this || _global).menu.classList.add("block"), setTimeout(function () {
                e.el.classList.add("open"), e.animationInProcess = !1;
              }), this.fireEvent("open", (this || _global).el), this.dispatch("open.hs.dropdown", (this || _global).el, (this || _global).el);
            }, t.prototype.close = function (e) {
              var t = this || _global;
              if (void 0 === e && (e = !0), (this || _global).animationInProcess || !(this || _global).el.classList.contains("open")) return !1;
              if ((this || _global).animationInProcess = !0, e) {
                var n = (this || _global).el.querySelector("[data-hs-dropdown-transition]") || (this || _global).menu;
                this.afterTransition(n, function () {
                  return t.destroyPopper();
                });
              } else this.destroyPopper();
              (this || _global).menu.style.margin = null, (this || _global).el.classList.remove("open"), this.fireEvent("close", (this || _global).el), this.dispatch("close.hs.dropdown", (this || _global).el, (this || _global).el);
            }, t.prototype.forceClearState = function () {
              this.destroyPopper(), (this || _global).menu.style.margin = null, (this || _global).el.classList.remove("open");
            }, t.getInstance = function (e, t) {
              var n = window.$hsDropdownCollection.find(function (t) {
                return t.element.el === ("string" == typeof e ? document.querySelector(e) : e);
              });
              return n ? t ? n : n.element.el : null;
            }, t.open = function (e) {
              var t = window.$hsDropdownCollection.find(function (t) {
                return t.element.el === ("string" == typeof e ? document.querySelector(e) : e);
              });
              t && t.element.menu.classList.contains("hidden") && t.element.open();
            }, t.close = function (e) {
              var t = window.$hsDropdownCollection.find(function (t) {
                return t.element.el === ("string" == typeof e ? document.querySelector(e) : e);
              });
              t && !t.element.menu.classList.contains("hidden") && t.element.close();
            }, t.accessibility = function (e) {
              (this || _global).history = c.menuSearchHistory;
              var t = window.$hsDropdownCollection.find(function (e) {
                return e.element.el.classList.contains("open");
              });
              if (t && (a.DROPDOWN_ACCESSIBILITY_KEY_SET.includes(e.code) || 4 === e.code.length && e.code[e.code.length - 1].match(/^[A-Z]*$/)) && !e.metaKey && !t.element.menu.querySelector("input:focus")) switch (console.log("Key code:", e.code), e.code) {
                case "Escape":
                  t.element.menu.querySelector(".hs-select.active") || (e.preventDefault(), this.onEscape(e));
                  break;
                case "Enter":
                  t.element.menu.querySelector(".hs-select button:focus") || t.element.menu.querySelector(".hs-collapse-toggle:focus") || this.onEnter(e);
                  break;
                case "ArrowUp":
                  e.preventDefault(), this.onArrow();
                  break;
                case "ArrowDown":
                  e.preventDefault(), this.onArrow(!1);
                  break;
                case "Home":
                  e.preventDefault(), this.onStartEnd();
                  break;
                case "End":
                  e.preventDefault(), this.onStartEnd(!1);
                  break;
                default:
                  e.preventDefault(), this.onFirstLetter(e.key);
              }
            }, t.onEscape = function (e) {
              var t = e.target.closest(".hs-dropdown.open");
              if (window.$hsDropdownCollection.find(function (e) {
                return e.element.el === t;
              })) {
                var n = window.$hsDropdownCollection.find(function (e) {
                  return e.element.el === t;
                });
                n && (n.element.close(), n.element.toggle.focus());
              } else this.closeCurrentlyOpened();
            }, t.onEnter = function (e) {
              var t = e.target.parentElement;
              if (window.$hsDropdownCollection.find(function (e) {
                return e.element.el === t;
              })) {
                e.preventDefault();
                var n = window.$hsDropdownCollection.find(function (e) {
                  return e.element.el === t;
                });
                n && n.element.open();
              }
            }, t.onArrow = function (e) {
              void 0 === e && (e = !0);
              var t = window.$hsDropdownCollection.find(function (e) {
                return e.element.el.classList.contains("open");
              });
              if (t) {
                var n = t.element.menu;
                if (!n) return !1;
                var o = (e ? Array.from(n.querySelectorAll("a:not([hidden]), .hs-dropdown > button:not([hidden])")).reverse() : Array.from(n.querySelectorAll("a:not([hidden]), .hs-dropdown > button:not([hidden])"))).filter(function (e) {
                    return !e.classList.contains("disabled");
                  }),
                  r = n.querySelector("a:focus, button:focus"),
                  i = o.findIndex(function (e) {
                    return e === r;
                  });
                i + 1 < o.length && i++, o[i].focus();
              }
            }, t.onStartEnd = function (e) {
              void 0 === e && (e = !0);
              var t = window.$hsDropdownCollection.find(function (e) {
                return e.element.el.classList.contains("open");
              });
              if (t) {
                var n = t.element.menu;
                if (!n) return !1;
                var o = (e ? Array.from(n.querySelectorAll("a")) : Array.from(n.querySelectorAll("a")).reverse()).filter(function (e) {
                  return !e.classList.contains("disabled");
                });
                o.length && o[0].focus();
              }
            }, t.onFirstLetter = function (e) {
              var t = this || _global,
                n = window.$hsDropdownCollection.find(function (e) {
                  return e.element.el.classList.contains("open");
                });
              if (n) {
                var o = n.element.menu;
                if (!o) return !1;
                var r = Array.from(o.querySelectorAll("a")),
                  i = function () {
                    return r.findIndex(function (n, o) {
                      return n.innerText.toLowerCase().charAt(0) === e.toLowerCase() && t.history.existsInHistory(o);
                    });
                  },
                  s = i();
                -1 === s && ((this || _global).history.clearHistory(), s = i()), -1 !== s && (r[s].focus(), (this || _global).history.addHistory(s));
              }
            }, t.closeCurrentlyOpened = function (e, t) {
              void 0 === e && (e = null), void 0 === t && (t = !0);
              var n = e && e.closest(".hs-dropdown") && e.closest(".hs-dropdown").parentElement.closest(".hs-dropdown") ? e.closest(".hs-dropdown").parentElement.closest(".hs-dropdown") : null,
                o = n ? window.$hsDropdownCollection.filter(function (e) {
                  return e.element.el.classList.contains("open") && e.element.menu.closest(".hs-dropdown").parentElement.closest(".hs-dropdown") === n;
                }) : window.$hsDropdownCollection.filter(function (e) {
                  return e.element.el.classList.contains("open");
                });
              e && e.closest(".hs-dropdown") && "inside" === window.HSStaticMethods.getClassPropertyAlt(e.closest(".hs-dropdown"), "--auto-close") && (o = o.filter(function (t) {
                return t.element.el !== e.closest(".hs-dropdown");
              })), o && o.forEach(function (e) {
                if ("false" === e.element.closeMode || "outside" === e.element.closeMode) return !1;
                e.element.close(t);
              });
            }, t.on = function (e, t, n) {
              var o = window.$hsDropdownCollection.find(function (e) {
                return e.element.el === ("string" == typeof t ? document.querySelector(t) : t);
              });
              o && (o.element.events[e] = n);
            }, t;
          }(s.default);
        window.addEventListener("load", function () {
          if (window.$hsDropdownCollection || (window.$hsDropdownCollection = []), document.querySelectorAll(".hs-dropdown:not(.--prevent-on-load-init)").forEach(function (e) {
            return new f(e);
          }), window.$hsDropdownCollection) {
            document.addEventListener("keydown", function (e) {
              return f.accessibility(e);
            }), window.addEventListener("click", function (e) {
              var t = e.target;
              f.closeCurrentlyOpened(t);
            });
            var e = window.innerWidth;
            window.addEventListener("resize", function () {
              window.innerWidth !== e && (e = innerWidth, f.closeCurrentlyOpened(null, !1));
            });
          }
        }), window.addEventListener("resize", function () {
          window.$hsDropdownCollection || (window.$hsDropdownCollection = []), window.$hsDropdownCollection.forEach(function (e) {
            return e.element.resizeHandler();
          });
        }), e.exports.HSDropdown = f, t.default = f;
      },
      969: (e, t, n) => {
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), t.menuSearchHistory = void 0;
        var o = n(489);
        t.menuSearchHistory = o.default;
      },
      489: (e, t) => {
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), t.default = {
          historyIndex: -1,
          addHistory: function (e) {
            (this || _global).historyIndex = e;
          },
          existsInHistory: function (e) {
            return e > (this || _global).historyIndex;
          },
          clearHistory: function () {
            (this || _global).historyIndex = -1;
          }
        };
      }
    },
    t = {};
  function n(o) {
    var r = t[o];
    if (void 0 !== r) return r.exports;
    var i = t[o] = {
      exports: {}
    };
    return e[o].call(i.exports, i, i.exports, n), i.exports;
  }
  return n.d = (e, t) => {
    for (var o in t) n.o(t, o) && !n.o(e, o) && Object.defineProperty(e, o, {
      enumerable: !0,
      get: t[o]
    });
  }, n.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t), n.r = e => {
    "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
      value: "Module"
    }), Object.defineProperty(e, "__esModule", {
      value: !0
    });
  }, n(610);
})());
export default exports;